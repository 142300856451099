.create-edit-return .category-titles {
  font-size: 12px;
  font-weight: bold;
  padding-right: 20px;
}

.create-edit-return .category-text {
  color: #00008b;
  min-width: 135px;
}

.create-edit-return .category-blue {
  color: #00008b;
}

.create-edit-return .image {
  cursor: pointer;
  height: auto;
  max-height: 180px;
  width: 70%;
}

.table-image {
  height: auto;
  max-height: 50px;
  width: 50px;
}

.create-edit-return .section-header {
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
}

.create-edit-return .info-text {
  font-size: 13px;
  font-style: italic;
}

.create-edit-return .placement-instructions {
  font-size: 15px;
  font-weight: bold;
  font-style: italic;
}

.create-edit-return .image-icon {
  color: #00008b;
  cursor: pointer;
  float: right;
  font-size: 25px;
  text-align: right;
}

.create-edit-return .image-icon:hover {
  color: gray;
}

.create-edit-return .box {
  display: flex;
  justify-content: center;
  text-align: center;
}

.create-edit-return .input-label {
  font-size: 14px;
}

.create-edit-return .comms-text {
  font-size: 14px;
}

.create-edit-return .comms-headers {
  font-weight: bold;
  text-decoration: underline;
}

.create-edit-return .reimburse-icon {
  color: #28a745;
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-left: 10px;
  text-align: right;
}

.create-edit-return .reimburse-icon:hover {
  color: darkgreen;
}

.reimbursed-row {
  background-color: rgba(40, 167, 69, 0.15) !important;
}

.reimbursed-row:hover {
  background-color: rgba(40, 167, 69, 0.25) !important;
}
